import React from "react";
import FiltroAnio from "./filtroanio.jsx";
import { GridCustom } from "./filtrogeneralperiodo.jsx";
import { LineChart } from "@mui/x-charts/LineChart";
import { useSelector } from "react-redux";
import { fetchProcGenerarGraficos, graf } from "../state/rootReducer.js";


const Dashboard = ({ arrFiltroPeriodo }) => {
  const graficos = useSelector(graf);
  return (
    <>
      <FiltroAnio arrFiltroPeriodo={arrFiltroPeriodo} fetchSaga={fetchProcGenerarGraficos}></FiltroAnio>
      <GridCustom container sx={{ height: "100%" }}>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
          <LineChart
            series={[{ data: graficos.cursos, label: "Matriculados", area: true, showMark: false, color: "RED" }]}
            xAxis={[{ scaleType: "point", data: graficos.mes }]}
            sx={{
              width: "100%",
              height: "100%",
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
          <LineChart
            series={[{ data: graficos.aprobados, label: "Aprobados", area: true, showMark: false }]}
            xAxis={[{ scaleType: "point", data: graficos.mes }]}
            sx={{
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
          <LineChart
            series={[{ data: graficos.reprobados, label: "Reprobados", area: true, showMark: false, color: "BLUE" }]}
            xAxis={[{ scaleType: "point", data: graficos.mes }]}
            sx={{
              width: "100%",
              height: "100%",
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
          <LineChart
            series={[{ data: graficos.pendientes, label: "Pendientes", area: true, showMark: false, color: "ORANGE" }]}
            xAxis={[{ scaleType: "point", data: graficos.mes }]}
            sx={{
              width: "100%",
              height: "100%",
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </GridCustom>
      </GridCustom>
    </>
  );
};

export default Dashboard;

/*

          
          
      <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>      
            
            
          
        */
